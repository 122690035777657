.logo {
    height: 30px;
  }
.MuiTableCell-root {
    border-bottom: 5px solid;
    border-color: LightSkyBlue;
}
.winnerth {
    border: 5px solid;
    border-bottom: 5px solid;
    border-color: LightSkyBlue;
}
.winnertb {
    border-left: 5px solid;
    border-right: 5px solid;
    
    border-left-color: LightSkyBlue;
    border-right-color: LightSkyBlue;
}
.winnertf {
    border: 5px solid;
    border-bottom: 5px solid;
    border-color: LightSkyBlue;
}