@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(min(2vmin, 24px));
}

.loadingIcon {
  animation: spinner 0.6s linear infinite;
  display: block;
  width: 24px;
  height: 24px;
  color: rgba(55, 53, 47, 0.4);
}
